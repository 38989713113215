exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cloud-we-work-js": () => import("./../../../src/pages/cloud-we-work.js" /* webpackChunkName: "component---src-pages-cloud-we-work-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-e-commerce-design-and-development-js": () => import("./../../../src/pages/e-commerce-design-and-development.js" /* webpackChunkName: "component---src-pages-e-commerce-design-and-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-app-design-and-development-js": () => import("./../../../src/pages/mobile-app-design-and-development.js" /* webpackChunkName: "component---src-pages-mobile-app-design-and-development-js" */),
  "component---src-pages-progressive-or-responsive-web-app-js": () => import("./../../../src/pages/progressive-or-responsive-web-app.js" /* webpackChunkName: "component---src-pages-progressive-or-responsive-web-app-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-support-maintenance-js": () => import("./../../../src/pages/support-maintenance.js" /* webpackChunkName: "component---src-pages-support-maintenance-js" */),
  "component---src-pages-third-party-integration-js": () => import("./../../../src/pages/third-party-integration.js" /* webpackChunkName: "component---src-pages-third-party-integration-js" */),
  "component---src-pages-website-design-js": () => import("./../../../src/pages/website-design.js" /* webpackChunkName: "component---src-pages-website-design-js" */),
  "component---src-pages-website-development-js": () => import("./../../../src/pages/website-development.js" /* webpackChunkName: "component---src-pages-website-development-js" */)
}

